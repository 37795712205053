import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatRow, MatTable } from '@angular/material/table';
import { Publication } from 'src/app/entities/publication.entity';
import { BehaviorSubject, catchError, forkJoin, Observable, Subscription, take, tap } from 'rxjs';
import { JsonApiTypeResponse } from 'src/app/models/JsonApiTypeResponse';
import { MatPaginator } from '@angular/material/paginator';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { select, Store } from '@ngrx/store';
import {
  PublicationDetailState
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.reducer';
import { ListViewService } from 'src/app/modules/list-view/list-view.service';
import { PaginatorService } from 'src/app/components/paginator/paginator.service';
import { first } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';
import {
  setPublicationToEdit
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.actions';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogData,
  PublicationDetailDialogComponent
} from 'src/app/dialogs/publication-detail-dialog/publication-detail-dialog.component';
import { Contact } from 'src/app/entities/contact.entity';
import { Agency } from 'src/app/entities/agency.entity';
import { AgencyService } from 'src/app/entities/services/agency.service';
import { PublicationService } from 'src/app/entities/services/publication.service';
import {
  getPublicationToEdit
} from 'src/app/modules/publication-detail/state/publication-detail/publication-detail.selectors';
import { DataPollingService } from 'src/app/services/data-polling.service';
import { PublicationStateService } from 'src/app/entities/services/publication-state.service';
import { PublicationState } from 'src/app/entities/publication-state.entity';
import { ContactDataService } from 'src/app/services/contact-data.service';
import { CacheService } from 'src/app/services/cache.service';
import { Division } from 'src/app/entities/division.entity';
import { Department } from 'src/app/entities/department.entity';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnDestroy, AfterViewInit {
  displayedColumns: string[] = [
    'priority',
    'orderNrNew',
    'title',
    'state',
    'note',
    'supervisorKom',
    'dguvSupervisor',
  ];
  dataSource: Publication[] = [];


  @ViewChild(MatTable) table: MatTable<Publication[] | null> | undefined;
  @ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef>;

  @ViewChild('paginatorListView') paginatorListView!: MatPaginator;

  public searchMode = false;

  private publicationsSub!: Subscription;
  public isLoading$ = new BehaviorSubject(false)

  public dguvSupervisors: Contact[] = [];
  private supervisorsKom: Contact[] = [];
  private layoutAgencies: Agency[] = [];
  private divisions: Division[] = [];
  private departments: Department[] = [];
  private graphic3dAgencies: Agency[] = [];
  private illustration2dAgencies: Agency[] = [];
  private lastPublications: Publication[] = [];
  private dataSubscription?: Subscription;
  private publicationStates?: PublicationState[];

  constructor(private cdr: ChangeDetectorRef,
              private configService: ConfigService,
              public dataService: DataService,
              private publicationDetailState: Store<PublicationDetailState>,
              public listViewService: ListViewService,
              public paginatorService: PaginatorService,
              public searchService: SearchService,
              public dialog: MatDialog,
              private agencyService: AgencyService,
              public publicationService: PublicationService,
              private dataPollingService:DataPollingService,
              private publicationStateService:PublicationStateService,
              private contactDataService:ContactDataService,
              private cacheService:CacheService

  ) {
    this.cacheService.clear();

    this.contactDataService.queryContactData().subscribe( )

    this.agencyService.layout$.subscribe( data => this.layoutAgencies = data)
    this.agencyService.graphic3d$.subscribe( data => this.graphic3dAgencies = data);
    this.agencyService.illustration2d$.subscribe( data => this.illustration2dAgencies = data);

    this.dataSource = [];

    this.publicationService.clearCache();
    this.publicationService.addAllToCache(this.paginatorService.allLoadedPublications);
    this.paginatorService.allLoadedPublications = []

    this.publicationService.entityActions$.subscribe((action) => {
      if(action.type === '[Publication] @ngrx/data/remove-all'){
        this.dataSource = []
      }
    })

    this.publicationService.entities$.subscribe((publications) => {
      if(publications !== this.lastPublications){
        this.updateDataSource(publications);
        this.paginatorService.allLoadedPublications.push(...publications);
        this.table?.renderRows();
        this.lastPublications = publications;
      }
    });

    if(paginatorService.selectedRowId === 0){
      this.loadPublications();
    }

    this.publicationService.loaded$.subscribe(loaded => {
      if(!loaded){
          this.searchMode = searchService.search.length !== 0;
      }
    });

    this.publicationDetailState.pipe(
      select(getPublicationToEdit)
    ).subscribe((publication) => {
      this.listViewService.selectedPublication = publication
      this.scrollToIndex(publication.id);
    })

    this.searchService.searchReset$.subscribe((reset) => {
      if (reset){
        this.loadPublications();
      }
    })

    this.searchService.quickSearchResult$.subscribe((publications) => {
      if(publications.length === 1){
        this.publicationSelected(publications[0]);
      }
    })

    this.publicationStateService.getAll().subscribe((data) => {
      this.publicationStates = data
    });
  }


  public ngAfterViewInit(): void {
    this.initTable();
  }

  private initTable(){
    this.table?.renderRows();
    // Needed to make 'scrollToIndex' work
    this.publicationService.addAllToCache([]);

    this.dataSubscription = this.dataPollingService.pollData().subscribe((data: Publication[]) => {
      if(data.length > 0){
        this.updateDataSource(data);
        this.table?.renderRows();
      }
    })
  }

  public ngOnDestroy(): void {
    if(this.publicationsSub){
      this.publicationsSub.unsubscribe();
    }

    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  updateDataSource(newPublications: Publication[]) {
    newPublications.forEach(newPub => {
      const index = this.dataSource.findIndex(existingPub => existingPub.id === newPub.id);
      if (index > -1) {
        // Überprüfen, ob sich die Daten unterscheiden
        if (JSON.stringify(this.dataSource[index]) !== JSON.stringify(newPub)) {
          // Ersetze den existierenden Eintrag, wenn die Daten sich unterscheiden
          this.dataSource[index] = newPub;
        }
      } else {
        // Füge den neuen Eintrag hinzu, wenn er noch nicht existiert
        this.dataSource.push(newPub);
      }
    });
  }

  private scrollToIndex(id: number): void {
    if (id && this.rows !== undefined){
      this.paginatorService.selectedRowId = id;
      let elem = this.rows.find(row => row.nativeElement.id === id.toString());

      requestAnimationFrame(() => {
        elem?.nativeElement.scrollIntoView({block: 'center', behavior: 'smooth'});
        this.paginatorService.selectedRowId = 0;
      });
      this.cdr.detectChanges();
    }
  }

  public detectChanges(publication: Publication){
      this.listViewService.selectedPublication = publication;
      this.cdr.detectChanges();
  }

  public publicationSelected(publication: Publication): void{
      this.publicationDetailState.dispatch(setPublicationToEdit({publicationToEdit: publication}))
      this.listViewService.selectedPublication = publication;
      this.paginatorService.selectedRowId = publication.id;
      this.paginatorService.currentIndex = this.dataSource.findIndex((e) => e === publication) + 1;
      this.openPublicationDetailDialog(publication)
  }

  openPublicationDetailDialog(publication: Publication) {
    // Prevent multiple opening of dialog
    // It can happen, that a method call this multiple times with the same publication
    if (this.dialog.getDialogById('publicationDetail')) return;

    this.dialog.open(PublicationDetailDialogComponent,{
      id: 'publicationDetail',
      data: {
        publication: publication,
        publicationStates: this.publicationStates,
        dguvSupervisors: this.contactDataService.dguvSupervisors,
        supervisorsKom: this.contactDataService.supervisorsKom,
        divisions: this.contactDataService.divisions,
        departments: this.contactDataService.departments,
        layoutAgencies: this.layoutAgencies,
        graphic3dAgencies: this.graphic3dAgencies,
        illustration2dAgencies: this.illustration2dAgencies,
      } as DialogData,
      height: "calc(100% - 30px)",
        width: "calc(100% - 30px)",
        maxWidth: "100%",
        maxHeight: "100%"
    });
  }
  public loadPublications($sortEvent?: Sort) {
    this.dataService.isLoading = true;
    this.isLoading$.next(true)

    const url = this.configService.config.apiUrl + this.configService.config.publicationUrl +
      '?page=' + this.listViewService.page +
      '&itemsPerPage=' + this.listViewService.itemsPerPage +
      this.searchService.searchString;

    this.dataService.makeGetCall<JsonApiTypeResponse<Publication>>(url, 'application/vnd.api+json').pipe(first()).subscribe((data) => {

      if($sortEvent){
        this.dataSource = [];
        this.publicationService.clearCache();
      }
      this.publicationService.addAllToCache(data.data);
      this.listViewService.dataMeta$.next(data.meta) ;
      this.dataService.isLoading = false;
    });
  }

  public sortData($event: Sort) {
    this.listViewService.page = 1;
    this.searchService.orderKey = $event.active;
    this.searchService.orderDirection = $event.direction;
    this.loadPublications($event);
  }

  public clearSearch() {
    this.searchService.search = [];
    this.publicationService.clearCache();
    this.loadPublications();
    this.searchMode = false;
  }

  public onNearEndScroll() {
    this.listViewService.page++;
      this.loadPublications();
  }
}
