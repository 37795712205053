import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Division } from 'src/app/entities/division.entity';

@Injectable({
  providedIn: 'root'
})
export class DivisionService extends EntityCollectionServiceBase<Division>{

  private _abteilung: Division[] = [];

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Division', serviceElementsFactory);
  }


  public get abteilung(): Division[] {
    return this._abteilung;
  }

  public set abteilung(value: Division[]) {
    this._abteilung = value;
  }
}
