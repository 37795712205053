import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Note } from 'src/app/entities/note.entity';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ArchivedNote } from 'src/app/models/archived-note.model';

@Component({
  selector: 'app-archive-view-note',
  templateUrl: './archive-view-note.component.html',
  styleUrls: ['./archive-view-note.component.scss']
})
export class ArchiveViewNoteComponent implements OnChanges{
  @Input() notes: string|undefined

  public archivedNotes$ = new BehaviorSubject<ArchivedNote[]|undefined>(undefined);

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.notes){
      const test: ArchivedNote[] = JSON.parse(this.notes);
      this.archivedNotes$.next(test);
    }
  }


}
