<div class="container align-middle justify-center mt-4 dark-form-field">
  <form [formGroup]="formGroup">
    <div>
      <div>
        <div class="flex justify-between">
          <div class="mr-2">
            <app-prio-button-row [priority]="formGroup.controls.priority.value || 0"
                                 (selectedPrio)="setPrio($event)"></app-prio-button-row>
          </div>
          <div class="mx-2.5">
            <mat-label class="mr-2">Aktiv</mat-label>
            <mat-button-toggle-group class="custom-button-toggle-group" [formControl]="formGroup.controls.isActive"
                                     (change)="buttonGroupSelected($event, 'isActive')">
              <mat-button-toggle  class="custom-button-toggle-group" value="true">
                <mat-icon>check</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="false">
                <mat-icon>radio_button_unchecked</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="disabled">
                <mat-icon>filter_alt_off</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="mx-2.5">
            <mat-label class="mr-2">Inhalt aktuell</mat-label>
            <mat-button-toggle-group [formControl]="formGroup.controls.contentIsUpToDate"
                                     (change)="buttonGroupSelected($event, 'contentIsUpToDate')">
              <mat-button-toggle value="true">
                <mat-icon>check</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="false">
                <mat-icon>radio_button_unchecked</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="disabled">
                <mat-icon>filter_alt_off</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="mx-2.5">
            <mat-label class="mr-2">Aktuelles CD</mat-label>
            <mat-button-toggle-group [formControl]="formGroup.controls.isLatestCD"
                                     (change)="buttonGroupSelected($event, 'isLatestCD')">
              <mat-button-toggle value="true">
                <mat-icon>check</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="false">
                <mat-icon>radio_button_unchecked</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="disabled">
                <mat-icon>filter_alt_off</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="ml-2.5">
            <mat-label class="mr-2">Bilder in Bilddatenbank</mat-label>
            <mat-button-toggle-group [formControl]="formGroup.controls.picturesInDb"
                                     (change)="buttonGroupSelected($event, 'picturesInDb')">
              <mat-button-toggle value="true">
                <mat-icon>check</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="false">
                <mat-icon>radio_button_unchecked</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="disabled">
                <mat-icon>filter_alt_off</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="flex justify-between mt-4 detail-search-row">
          <div>
            <mat-form-field class="w-full">
              <mat-label>Best.-Nr</mat-label>
              <input matInput [formControl]="formGroup.controls.orderNrNew"
                     (change)="textPropertySelected($event, 'orderNrNew')">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="w-full">
              <mat-label>Best.-Nr. Alt</mat-label>
              <input matInput [formControl]="formGroup.controls.orderNrOld"
                     (change)="textPropertySelected($event, 'orderNrOld')">
            </mat-form-field>
          </div>

          <div class="ml-2.5">
            <mat-form-field class="w-full">
              <mat-label>Ausgabe</mat-label>
              <input matInput [formControl]="formGroup.controls.edition"
                     (change)="textPropertySelected($event, 'edition')">
            </mat-form-field>
          </div>
        </div>
        <div class="flex" >
            <mat-form-field class="w-full">
              <mat-label>Titel</mat-label>
              <input matInput [formControl]="formGroup.controls.title"
                     (change)="textPropertySelected($event, 'title')">
            </mat-form-field>
        </div>
        <div class="flex justify-between detail-search-row">
          <div class="w-1/4 mr-2.5">
            <mat-form-field class="w-full">
              <mat-label>Überprüfung erforderlich ab</mat-label>
              <input matInput [matDatepicker]="picker"
                     [formControl]="formGroup.controls.reviewRequiredOn"
                     (dateChange)="dateSelected($event, 'reviewRequiredOn')">
              <mat-hint>DD.MM.YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="w-1/4 mx-2.5">
            <mat-form-field class="w-full">
              <mat-label>Frist</mat-label>
              <input matInput [matDatepicker]="deadlinePicker"
                     [formControl]="formGroup.controls.deadline"
                     (dateChange)="dateSelected($event, 'deadline')">
              <mat-hint>DD.MM.YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
              <mat-datepicker #deadlinePicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="w-1/4 mx-2.5">
            <app-select [listItems]="publicationStates" class="w-full"
                        [label]="'Status'"
                        [ariaLabel]="'state'"
                        [acmFormControl]="formGroup.controls.state"
                        [optionValuePrefix]="'/api/states'"
                        [value]="publicationSearchValues.state"
                        (selectedElement)="optionSelected($event, publicationStateService)"
            >
            </app-select>
          </div>
          <div class="w-1/4 ml-2">
            <mat-form-field class="w-full" [matTooltip]="formGroup.controls.projectFolderPath?.getRawValue()||''">
              <mat-label>Pfad Projektordner</mat-label>
              <input matInput [formControl]="formGroup.controls.projectFolderPath"
                     (change)="textPropertySelected($event, 'projectFolderPath')">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div class="flex justify-between">
          <div class="w-1/4 mr-2">
            <app-select [listItems]="divisions" class="w-full"
                        [label]="'Abteilung'"
                        [ariaLabel]="'division'"
                        [acmFormControl]="formGroup.controls.division"
                        [optionValuePrefix]="'/api/' + divisionService.entityName|iriPluralize"
                        [value]="publicationSearchValues.division"
                        (selectedElement)="optionSelected($event, divisionService)"
            >
            </app-select>
          </div>
          <div class="w-1/4 mx-2.5">
            <app-select [listItems]="departments" class="w-full"
                        [label]="'Referat'"
                        [ariaLabel]="'department'"
                        [acmFormControl]="formGroup.controls.department"
                        [optionValuePrefix]="'/api/' + departmentService.entityName|iriPluralize"
                        [value]="publicationSearchValues.department"
                        (selectedElement)="optionSelected($event, departmentService)"
            >
            </app-select>
          </div>

          <div class="w-1/4 mx-2.5">
            <app-select [listItems]="dguvSupervisors" class="w-full"
                        [label]="'DGUV Betreuer'"
                        [ariaLabel]="'dguvSupervisor'"
                        [acmFormControl]="formGroup.controls.dguvSupervisor"
                        [optionValuePrefix]="'/api/' + contactService.entityName|iriPluralize"
                        [value]="publicationSearchValues.dguvSupervisor"
                        (selectedElement)="contactSelected($event, contactService)"
            >
            </app-select>
          </div>

          <div class="w-1/4 ml-2">
            <app-select [listItems]="supervisorsKom" class="w-full"
                        [label]="'Betreuer KOM'"
                        [ariaLabel]="'supervisorKom'"
                        [acmFormControl]="formGroup.controls.supervisorKom"
                        [optionValuePrefix]="'/api/'+contactService.entityName|iriPluralize"
                        [value]="publicationSearchValues.supervisorKom"
                        (selectedElement)="contactSelected($event, contactService)"
            >
            </app-select>
          </div>
        </div>
        <div class="grid grid-cols-4 grid-flow-row grid-rows-1">
          <div class="mr-2.5 ">
            <mat-form-field class="w-full">
              <mat-label>Anmerkung</mat-label>
              <textarea matInput [formControl]="formGroup.controls.note"
                        (change)="textPropertySelected($event, 'note')">
              </textarea>
            </mat-form-field>
          </div>

          <div class="col-span-2 row-span-1">
            <div class="mx-2.5 ">
              <mat-form-field class="w-full">
                <mat-label>Druckspezifikationen</mat-label>
                <textarea matInput [formControl]="formGroup.controls.printSpecifications"
                          (change)="textPropertySelected($event, 'printSpecifications')">
              </textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-4 grid-flow-row grid-rows-1">
          <div class="mr-2.5 "><!-- Col 1 -->
            <div class="flex absolute inset-x-0 bottom-0 mb-5">
              <div>
                <button class="m-2" mat-raised-button color="primary" (click)="search()">Suchen</button>
              </div>
              <div>
                <button class="m-2" mat-stroked-button color="secondary" (click)="resetSearch()">Reset</button>
              </div>
              <div>
                <button class="m-2" mat-button color="secondary" (click)="close()">Schließen</button>
              </div>
            </div>
          </div>
          <div class="mx-2.5 p-1 col-span-2 border border-solid border-gray-300"><!-- Col 2 -->
            <div class="detail-search-row">
              <mat-form-field class="w-full">
                <mat-label>Notiz</mat-label>
                <input matInput [formControl]="formGroup.controls.notesNote"
                       (change)="noteSearch($event, 'notes.note')">
              </mat-form-field>
            </div>
            <div class="detail-search-row">
              <mat-form-field class="w-full">
                <mat-label>Benutzer</mat-label>
                <input matInput [formControl]="formGroup.controls.notesChangeUser"
                       (change)="noteSearch($event, 'notes.changeUser.initials')">
              </mat-form-field>
            </div>
            <div class="detail-search-row">
              <mat-form-field class="w-full ">
                <mat-label>Notiz Datum</mat-label>
                <input matInput [matDatepicker]="notesDatePicker"
                       [formControl]="formGroup.controls.notesDate"
                       (dateChange)="dateSelected($event, 'notes.createdAt')">
                <mat-datepicker-toggle matIconSuffix [for]="notesDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #notesDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="ml-2"><!-- Col 3 -->
            <div class="flex align-bottom w-full detail-search-row">
              <app-select [listItems]="agencies" class="w-full"
                          [label]="'Layout'"
                          [ariaLabel]="'layout'"
                          [acmFormControl]="formGroup.controls.layout"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationSearchValues.layout"
                          (selectedElement)="optionSelected($event, agencyService)"
              >
              </app-select>
            </div>

            <div class="flex align-bottom w-full detail-search-row">
              <app-select [listItems]="graphic3dAgencies" class="w-full"
                          [label]="'Grafiken 3D'"
                          [ariaLabel]="'graphic3d'"
                          [acmFormControl]="formGroup.controls.graphic3d"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationSearchValues.graphic3d"
                          (selectedElement)="optionSelected($event, agencyService)"
              >
              </app-select>
            </div>

            <div class="flex align-bottom w-full detail-search-row">
              <app-select [listItems]="illustration2dAgencies" class="w-full"
                          [label]="'Illustrationen 2D'"
                          [ariaLabel]="'illustration2d'"
                          [acmFormControl]="formGroup.controls.illustration2d"
                          [optionValuePrefix]="'/api/'+agencyService.entityName|iriPluralize"
                          [value]="publicationSearchValues.illustration2d"
                          (selectedElement)="optionSelected($event, agencyService)"
              >
              </app-select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

