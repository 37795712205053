import { Injectable } from '@angular/core';
import { login, setLoginChecked, setToken } from 'src/app/state/app-user-state/app-user.actions';
import { AppUser } from 'src/app/models/app-user.model';
import { AuthToken } from 'src/app/models/auth-token.model';
import { DataService } from 'src/app/services/data.service';
import { select, Store } from '@ngrx/store';
import { AppUserState } from 'src/app/state/app-user-state/app-user.reducer';
import { ConfigService } from 'src/app/services/config.service';
import { getLogoutState } from 'src/app/state/app-user-state/app-user.selectors';
import { CookieService } from 'ngx-cookie-service';
import { NotifyService } from 'src/app/services/notify.service';
import { catchError, EMPTY, Observable, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private apiHost = '';
  private tokenInterval = 3600;
  private appUser: AppUser = {} as AppUser;

  private cookieValidated = false;

  private tokenCycle: ReturnType<typeof setInterval>  | undefined;

  constructor(private dataService: DataService,
              private appUserState: Store<AppUserState>,
              private configService: ConfigService,
              private cookieService: CookieService,
              private notifyService: NotifyService,
              )
  {
    this.tokenInterval = this.configService.config.authentication.token.lifeCycle;
    this.apiHost = this.configService.config.apiUrl;

    this.appUserState.pipe(select(getLogoutState)).subscribe((logout) => {
      if(logout){
        this.logout();
      }
    })
  }

  public login(loginData: any): Observable<boolean> {
    const username = loginData.username;

    return this.dataService
      .makePostCall<AuthToken>(this.configService.config.apiUrl + this.configService.config.authentication.api, loginData)
      .pipe(
        map(response => {
          const appUser = {
            userId: '',
            username: username,
            token: response.token,
            refreshToken: response.refresh_token
          } as AppUser;

          return appUser;
        }),
        tap(appUser => {
          this.appUserState.dispatch(login(appUser));
          this.setUserDataToken(appUser);
          this.startTokenRefreshCycle(appUser.refreshToken);
          this.notifyService.toast('success', 'Logged in');
        }),
        map(() => true),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            this.notifyService.toast('error', 'Anmeldung fehlgeschlagen. Bitte prüfen Sie Ihre Anmeldedaten.');
            return of(false);
          }
          throw error;
        })
      );
  }

  public checkUserCookie() {
    const appUser = this.getAppUserFromCookie();

    if (appUser !== null) {
      this.appUser = appUser;
      this.appUserState.dispatch(login(this.appUser));
      this.refreshToken(appUser.refreshToken);

      this.startTokenRefreshCycle(appUser.refreshToken);
    } else {
      this.appUserState.dispatch(setLoginChecked({checked: true}))
    }
  }

  private getAppUserFromCookie(): AppUser|null {
    const userData = this.cookieService.get('userData') ;

    if ((typeof userData !== 'undefined') && (userData.length > 10)) {
      const decoded = atob(userData);

      const appUserCookie: AppUser = JSON.parse(decoded);

      if ((typeof appUserCookie !== 'undefined')) {

        const appUser = {
          userId: '',
          username: appUserCookie.username,
          token: appUserCookie.token,
          refreshToken: appUserCookie.refreshToken
        } as AppUser;

        return appUser
      }
    }

    return null;
  }

  private startTokenRefreshCycle(refreshToken: string) {
    if (typeof this.tokenCycle === 'undefined') {
      this.tokenCycle = setInterval(() => {
        this.refreshToken(refreshToken);
      }, this.tokenInterval)
    }
  }

  private refreshToken(refreshToken: string) {

    const refreshData = {
      refresh_token: refreshToken
    }
    return this.dataService.makePostCall<AuthToken>(this.apiHost+this.configService.config.authentication.tokeRefreshUrl, refreshData)
      .subscribe( {
        next: (response) => {
          this.appUserState.dispatch(setToken({token: response.token}))
          this.appUser.token = response.token;
          this.appUser.refreshToken = response.refresh_token;
          this.setUserDataToken(this.appUser);

          if(!this.cookieValidated){
            this.cookieValidated = true;
            this.appUserState.dispatch(login(this.appUser));
          }
        },
        error: () => {
          this.logout();
          this.notifyService.toast('info', 'You have been logged out.');
        }

      });
  }

  public setUserDataToken(appUser: AppUser) {

    const stringify = JSON.stringify(appUser);
    const serial = btoa(stringify);

    this.cookieService.set('userData', serial, 9999, '/');
  }

  private clearTokenRefreshCycle() {
    clearInterval(this.tokenCycle);
  }

  private logout(){
    this.cookieService.delete('userData');
    this.clearTokenRefreshCycle();
  }
}
