import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });


  constructor(private fb: FormBuilder,
              private sessionService:SessionService,
              private router:Router
              ) { }

  public ngOnInit(): void {

  }

  public submit() {
    if (this.loginForm.valid) {
      this.sessionService.login(this.loginForm.getRawValue()).subscribe((result) => {
        if(result){
          this.router.navigate(['/list'])
        }
      })
    }
  }








}
