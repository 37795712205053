import { Injectable } from '@angular/core';
import { ContactTypes } from 'src/app/types/contact-types.enum';
import { ContactService } from 'src/app/entities/services/contact.service';
import { DivisionService } from 'src/app/entities/services/division.service';
import { DepartmentService } from 'src/app/entities/services/department.service';
import { forkJoin, Observable, ReplaySubject, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from 'src/app/entities/contact.entity';
import { Division } from 'src/app/entities/division.entity';
import { Department } from 'src/app/entities/department.entity';
import { CacheService } from 'src/app/services/cache.service';

interface QueryConfig {
  name: ContactTypes;
  query?: string;
  service: {
    getWithQuery?: (query: string) => Observable<any[]>;
    getAll?: () => Observable<any[]>;
  };
  updateMethod: (data: any[]) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ContactDataService {
  public dguvSupervisors$ = new ReplaySubject<Contact[]>(1);
  public supervisorsKom$ = new ReplaySubject<Contact[]>(1);
  public divisions$ = new ReplaySubject<Division[]>(1);
  public departments$ = new ReplaySubject<Department[]>(1);

  private _dguvSupervisors: Contact[] = [];
  private _supervisorsKom: Contact[] = [];
  private _divisions: Division[] = [];
  private _departments: Department[] = [];

  constructor(
    private contactService: ContactService,
    private divisionService: DivisionService,
    private departmentService: DepartmentService,
    private cacheService: CacheService
  ) {}

  public queryContactData(clearCache = false): Observable<Map<ContactTypes, any[]>> {
    const queries: QueryConfig[] = [
      {
        name: ContactTypes.DguvBetreuer,
        query: `isActive=1&contactTypes.name=${ContactTypes.DguvBetreuer}`,
        service: this.contactService,
        updateMethod: (data: Contact[]) => {
          this._dguvSupervisors = data;
          this.dguvSupervisors$.next(data);
          this.contactService.dguvBetreuer = data;
        }
      },
      {
        name: ContactTypes.BetreuerKom,
        query: `isActive=1&contactTypes.name=${ContactTypes.BetreuerKom}`,
        service: this.contactService,
        updateMethod: (data: Contact[]) => {
          this._supervisorsKom = data;
          this.supervisorsKom$.next(data);
          this.contactService.betreuerKom = data;
        }
      },
      {
        name: ContactTypes.Abteilungen,
        service: this.divisionService,
        updateMethod: (data: Division[]) => {
          this._divisions = data;
          this.divisions$.next(data)
          this.divisionService.abteilung = data;
        }
      },
      {
        name: ContactTypes.Referate,
        service: this.departmentService,
        updateMethod: (data: Department[]) => {
          this._departments = data;
          this.departments$.next(data)
          this.departmentService.referat = data;
        }
      }
    ];

    const observables = queries.map(q => {
      if (clearCache) {
        this.cacheService.clear('/api/'+q.query || '/api/'+q.name);
      }
      return q.query
        ? q.service.getWithQuery!(q.query)
        : q.service.getAll!();
    });

    return forkJoin(observables).pipe(
      map(results => {
        const resultMap = new Map<ContactTypes, any[]>();
        queries.forEach((q, index) => {
          const data = results[index];
          resultMap.set(q.name, data);
          q.updateMethod(data);
        });
        return resultMap;
      })
    );
  }

  public get dguvSupervisors(): Contact[] {
    return this._dguvSupervisors;
  }

  public set dguvSupervisors(value: Contact[]) {
    this._dguvSupervisors = value;
  }

  public get supervisorsKom(): Contact[] {
    return this._supervisorsKom;
  }

  public set supervisorsKom(value: Contact[]) {
    this._supervisorsKom = value;
  }

  public get divisions(): Division[] {
    return this._divisions;
  }

  public set divisions(value: Division[]) {
    this._divisions = value;
  }

  public get departments(): Department[] {
    return this._departments;
  }

  public set departments(value: Department[]) {
    this._departments = value;
  }
}
