import { Component, OnDestroy, OnInit } from '@angular/core';
import { Contact } from 'src/app/entities/contact.entity';
import { MatTableDataSource } from '@angular/material/table';
import { NotifyService } from 'src/app/services/notify.service';
import { Subscription } from 'rxjs';
import {
  DeleteContactDialogComponent,
  DeleteContactDialogData
} from 'src/app/modules/administration/dialogs/delete-contact-dialog/delete-contact-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from 'src/app/entities/services/contact.service';
import { ContactTypeService } from 'src/app/entities/services/contact-type.service';
import { DataService } from 'src/app/services/data.service';
import { ContactDataService } from 'src/app/services/contact-data.service';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-contact-admin',
  templateUrl: './contact-admin.component.html',
  styleUrls: ['./contact-admin.component.scss']
})
export class ContactAdminComponent implements OnInit, OnDestroy{

  public dataSource = new MatTableDataSource<Contact>();

  public displayedColumns: string[] = [
    'isActive',
    'salutation',
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'contactType',
    'city',
    'tools'
  ];
  private sub: Subscription | undefined;
  private contactDataSub?: Subscription;

  constructor(
              private notifyService: NotifyService,
              public dialog: MatDialog,
              private contactService: ContactService,
              private contactTypeService:ContactTypeService,
              private dataService:DataService,
              private contactDataService:ContactDataService,
              private cacheService:CacheService
  ) {

  }

  public ngOnInit(): void {
    this.dataService.isLoading = true;
    this.sub = this.contactService.entities$.subscribe((contacts) => {
      this.dataSource.data = contacts
    })

    this.cacheService.clear('/api/contact')
    this.contactTypeService.getAll();

    this.contactService.clearCache();
    this.contactService.getAll().subscribe(() => this.dataService.isLoading = false);
  }

  public ngOnDestroy(): void {
    if(this.sub){
      this.sub.unsubscribe();
    }

    if(this.contactDataSub){
      this.contactDataSub.unsubscribe();
    }
  }

  public toggleContactActive(contact: Contact) {
    contact.isActive = !contact.isActive;

    this.contactService.update(contact).subscribe((contact) => {
      this.contactDataSub = this.contactDataService.queryContactData().subscribe(() => {
        this.cacheService.clear('/api/contact')
        this.notifyService.toast('success', 'Status des Betreuers geändert')
      })
    })
  }

  public confirmDelete(contact: Contact ) {
    const dialogRef = this.dialog.open(DeleteContactDialogComponent, {
      data: {contact} as DeleteContactDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.contactService.delete(contact).subscribe(() => {
          this.notifyService.toast('success', 'Betreuer wurde gelöscht');
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }
}
