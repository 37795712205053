import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Agency } from 'src/app/entities/agency.entity';
import { catchError, forkJoin, Observable, of, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgencyService extends EntityCollectionServiceBase<Agency> {

  public layout$ = new ReplaySubject<Agency[]>();
  public graphic3d$ = new ReplaySubject<Agency[]>();
  public illustration2d$ = new ReplaySubject<Agency[]>();
  private _layout: Agency[] = [];
  private _graphic3d: Agency[] = [];
  private _illustration2d: Agency[] = [];

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Agency', serviceElementsFactory);
  }

  getAgenciesWithQuery(query: string): Observable<Agency[]> {
    return super.getWithQuery(query).pipe(
      catchError(error => {
        console.error(`Error fetching agencies with query "${query}":`, error);
        return of([]); // Gibt ein leeres Array zurück im Fehlerfall
      })
    );
  }

  public queryAgencies(): Observable<{layout: Agency[], graphic3d: Agency[], illustration2d: Agency[]}>{
    return forkJoin({
      layout: this.getAgenciesWithQuery('isActive=1&agencyTypes.name=Layout').pipe(
        tap(agencies => {
          this.layout$.next(agencies);
          this._layout = agencies;
        }),
        catchError(error => {
          console.error('Error fetching layout agencies:', error);
          return of([]);
        })
      ),
      graphic3d: this.getAgenciesWithQuery('isActive=1&agencyTypes.name=Grafiken 3D').pipe(
        tap(agencies => {
          this.graphic3d$.next(agencies);
          this._graphic3d = agencies;
        }),
        catchError(error => {
          console.error('Error fetching 3D graphic agencies:', error);
          return of([]);
        })
      ),
      illustration2d: this.getAgenciesWithQuery('isActive=1&agencyTypes.name=Illustration 2D').pipe(
        tap(agencies => {
          this.illustration2d$.next(agencies);
          this._illustration2d = agencies;
        }),
        catchError(error => {
          console.error('Error fetching 2D illustration agencies:', error);
          return of([]);
        })
      )
    });
  }

  public get layout(): Agency[] {
    return this._layout;
  }

  public set layout(value: Agency[]) {
    this._layout = value;
  }

  public get graphic3d(): Agency[] {
    return this._graphic3d;
  }

  public set graphic3d(value: Agency[]) {
    this._graphic3d = value;
  }

  public get illustration2d(): Agency[] {
    return this._illustration2d;
  }

  public set illustration2d(value: Agency[]) {
    this._illustration2d = value;
  }
}
