import { createAction, props } from '@ngrx/store';
import { AuthToken } from 'src/app/models/auth-token.model';
import { AppUser } from 'src/app/models/app-user.model';

export const APP_USER_ACTION_TYPE = '[AppUser]';

export const loadPortalUsers = createAction(
  `${APP_USER_ACTION_TYPE} Load AppUsers`,
);

export const login = createAction(
  `${APP_USER_ACTION_TYPE} Login user`,
  props<AppUser>(),
);

export const logout = createAction(
  `${APP_USER_ACTION_TYPE} Logout user`,
);
export const setToken = createAction(
  `${APP_USER_ACTION_TYPE} Set user token`,
  props<{ token: string }>(),
);

export const setBackendApiBaseUrl = createAction(
  `${APP_USER_ACTION_TYPE} Set backend API base Url`,
  props<{ backenBaseApiUrl: string }>(),
);

export const refreshTokens = createAction(
  `${APP_USER_ACTION_TYPE} Refresh tokens`,
  props<AuthToken>(),
);

export const setLoginChecked = createAction(
  `${APP_USER_ACTION_TYPE} set login checked`,
  props<{ checked: boolean }>(),
);
