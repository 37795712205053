import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataServiceError, EntityCollectionService, EntityServices } from '@ngrx/data';
import { Contact } from 'src/app/entities/contact.entity';
import { catchError, EMPTY, Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { ContactType } from 'src/app/entities/contact-type.entity';
import { NotifyService } from 'src/app/services/notify.service';
import { first } from 'rxjs/operators';
import { ContactDataService } from 'src/app/services/contact-data.service';

@Component({
  selector: 'app-contact-admin-form',
  templateUrl: './contact-admin-form.component.html',
  styleUrls: ['./contact-admin-form.component.scss']
})
export class ContactAdminFormComponent implements OnDestroy {
  public contactService: EntityCollectionService<Contact>;
  private contactTypeService: EntityCollectionService<ContactType>;

  public editMode: 'add' | 'edit' | undefined;

  private selectedContactId: number;

  public contactToEdit?: Contact;

  private contactDataSub?: Subscription;
  private typesSub: Subscription | undefined;

  private readonly sub: Subscription | undefined;

  public contactFormGroup = this.fb.group({
    isActive: [false],
    name: [''],
    salutation: [''],
    firstName: [''],
    lastName: [''],
    email: [''],
    city: [''],
    phoneNumber: [''],
    contactTypes: this.fb.group({
      dguvBetreuer: [false],
      betreuerKom: [false]
    }),
  });
  private contactTypes: ContactType[] = [];

  constructor(
    private entityServices: EntityServices,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private contactDataService:ContactDataService
  ) {
    this.contactService = this.entityServices.getEntityCollectionService('Contact');
    this.contactTypeService = this.entityServices.getEntityCollectionService('ContactType');

    this.contactTypeService.getAll();

    this.selectedContactId = Number(this.route.snapshot.paramMap.get('contactId'));

    this.typesSub = this.contactTypeService.entities$.subscribe((contactTypes) => {
      this.contactTypes = contactTypes;

      this.initContact();
    });

    if (this.selectedContactId !== 0) {
      this.sub = this.contactService.entities$.subscribe((contacts) => {
        this.contactToEdit = contacts.find((contact) => contact.id === this.selectedContactId);

        if (typeof this.contactToEdit === 'undefined') {
          this.contactService.getByKey(this.selectedContactId);
        }

        this.editMode = 'edit';
        // this.initContact();
      });
    } else {
      this.contactToEdit = {} as Contact;
      this.editMode = 'add';
    }
  }

  private initContact() {
    if(this.contactToEdit === undefined){
      this.contactToEdit = {} as Contact;
    }

    Object.keys(this.contactFormGroup.controls).forEach(control => {
      if (control !== 'contactTypes') {
        // @ts-ignore
        this.contactFormGroup.controls[control].setValue(this.contactToEdit[control]);
      }
    });

    this.contactFormGroup.controls.contactTypes.controls.dguvBetreuer.setValue(
      this.checkIfContactIsChecked(this.contactToEdit?.contactTypes, 'dguvBetreuer')
    );

    this.contactFormGroup.controls.contactTypes.controls.betreuerKom.setValue(
      this.checkIfContactIsChecked(this.contactToEdit?.contactTypes, 'betreuerKom')
    );
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.typesSub) {
      this.typesSub.unsubscribe();
    }

    if (this.contactDataSub) {
      this.contactDataSub.unsubscribe();
    }
  }

  public save() {

    if (this.contactToEdit === undefined) {
      return
    }

    Object.keys(this.contactFormGroup.controls).forEach(control => {
      if (control !== 'contactTypes') {
        // @ts-ignore
        let value = this.contactFormGroup.controls[control].value
        if(value !== undefined){
          if (typeof value === 'string') {
          // @ts-ignore
            this.contactToEdit[control] = value.trim();
          } else {
          // @ts-ignore
            this.contactToEdit[control] = value;
          }
        }

      }
    });

    this.contactToEdit = this.buildContactTypeProperty(this.contactToEdit);

    if (this.editMode === 'add') {
      this.contactToEdit.isActive = true;
      this.contactService.add(this.contactToEdit).pipe(
        first(),
        catchError(error => {
          this.printErrorMessage(error);
          return EMPTY;
        })).subscribe(() => {
        this.contactDataSub = this.contactDataService.queryContactData().subscribe(() => {
            this.notifyService.toast('success', 'Betreuer hinzugefügt');
          })
      });
    } else {
      this.contactService.update(this.contactToEdit).pipe(
        first(),
        catchError(error => {
          this.printErrorMessage(error);
          return EMPTY;
        })).subscribe(() => {
          this.contactDataSub = this.contactDataService.queryContactData().subscribe(() => {
            this.notifyService.toast('success', 'Änderungen erfolgreich gespeichert.');
          });
      });
    }
  }

  private printErrorMessage(error: any) {
    console.log('error', error);

    let messageAdd = 'Unexpected error';

    if (error && error instanceof DataServiceError) {
      console.log('DataServiceError error.error', error.error);
      const dataServiceError = error.error;

      if (dataServiceError.error &&
        dataServiceError.error['hydra:description']) {

        messageAdd = dataServiceError.error['hydra:description'];
        console.error('Error description:', messageAdd);

        // Hier können Sie mit der Beschreibung arbeiten
        // z.B. sie dem Benutzer anzeigen oder für Logging verwenden
      }
    }

    if (messageAdd.includes('Duplicate')) {
      messageAdd = 'E-Mailadresse bereits vorhanden';
    }

    this.notifyService.toast('error', 'Betreuer konnte nicht gespeichert werden. ' + messageAdd);
  }

  private buildContactTypeProperty(contact: Contact): Contact {
    contact.contactTypes = [];

    Object.keys(this.contactFormGroup.controls.contactTypes.controls).forEach(controlName => {
      let control = this.contactFormGroup.controls.contactTypes.get(controlName);

      if (control?.value) {
        let contactType = this.contactTypes.find((contactType) => contactType.name === controlName);
        if (typeof contactType === 'undefined') {
          this.notifyService.toast('error', 'Unerwarteter Fehler: Keinen Betreuer-Typ gefunden: ' + controlName);
          throw new Error('Unerwarteter Fehler: Keinen Betreuer-Typ gefunden');
        }
        contact.contactTypes.push('/api/contact_types/' + contactType?.id);
      }
    });

    return contact;
  }

  private checkIfContactIsChecked(contactTypes?: string[], expectedType?: string): boolean {
    let result = undefined;

    this.contactTypes.forEach((contactType) => {
      if (contactType.name === expectedType) {
        let contactTypeId = contactType.id.toString();
        result = contactTypes?.find((iri) => iri.includes(contactTypeId));
      }
    });

    return result !== undefined;
  }
}
