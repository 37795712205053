import { createReducer, on } from '@ngrx/store';
import * as AppUserActions from './app-user.actions';
import jwtDecode from 'jwt-decode';
import { JwtToken } from 'src/app/models/JwtToken';
import { AppUser } from 'src/app/models/app-user.model';

export const appUserFeatureKey = 'appUser';

export interface AppUserState {
  userId: string;
  username: string;
  token: string;
  refreshToken: string;
  roles: string[];
  logOut: boolean;
  loginChecked: boolean;
}

export const initialState: AppUserState = {
  userId: '',
  username: '',
  token: '',
  refreshToken: '',
  roles: [],
  logOut: false,
  loginChecked: false
};

export const appUserReducer = createReducer(
  initialState,
  on(AppUserActions.login, (state, appUser: AppUser) => {
    const decoded = jwtDecode(appUser.token) as JwtToken;

    return {
      ...state,
      userId: appUser.userId,
      username: appUser.username,
      token: appUser.token,
      refreshToken: appUser.refreshToken,
      roles: decoded.roles,
      loginChecked: true
    };
  }),
  on(AppUserActions.logout, (state) => (
    {...state, ...initialState, logOut: true, loginChecked: true})
  ),
  on(AppUserActions.setToken, (state, token) => {
    const decoded = jwtDecode(token.token) as JwtToken;
    return {
        ...state,
        token: token.token,
        roles: decoded.roles,
        loginChecked: true
      }
  }
  ),
  on(AppUserActions.setLoginChecked, (state, checked) => (
    {
      ...state,
      loginChecked: checked.checked
    })
  ),
);
