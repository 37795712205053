import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Contact } from 'src/app/entities/contact.entity';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends EntityCollectionServiceBase<Contact>{

  private _dguvBetreuer: Contact[] = [];
  private _betreuerKom: Contact[] = [];

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Contact', serviceElementsFactory);
  }


  public get dguvBetreuer(): Contact[] {
    return this._dguvBetreuer;
  }

  public set dguvBetreuer(value: Contact[]) {
    this._dguvBetreuer = value;
  }

  public get betreuerKom(): Contact[] {
    return this._betreuerKom;
  }

  public set betreuerKom(value: Contact[]) {
    this._betreuerKom = value;
  }
}
