import { Component } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Department } from 'src/app/entities/department.entity';
import { MatTableDataSource } from '@angular/material/table';
import { NotifyService } from 'src/app/services/notify.service';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteDepartmentDialogComponent,
  DeleteDepartmentDialogData
} from 'src/app/modules/administration/dialogs/delete-department-dialog/delete-department-dialog.component';
import { ContactDataService } from 'src/app/services/contact-data.service';

@Component({
  selector: 'app-department-admin',
  templateUrl: './department-admin.component.html',
  styleUrls: ['./department-admin.component.scss']
})
export class DepartmentAdminComponent {

  public departmentService: EntityCollectionService<Department>;

  public dataSource = new MatTableDataSource<Department>();

  public displayedColumns: string[] = ['id', 'name'];
  public departmentToEdit = {} as Department;

  public inputIsDisabled = true;

  constructor(private entityServices: EntityServices,
              private notifyService: NotifyService,
              public dialog: MatDialog,
              private contactDataService:ContactDataService
  ) {
    this.departmentService = this.entityServices.getEntityCollectionService('Department');
    this.departmentService.getAll();
   this.departmentService.entities$.subscribe((departments) => {
     this.dataSource.data = departments
   })

  }

  public addDepartment(){
    this.departmentToEdit = {} as Department;
    this.inputIsDisabled = false;
  }

  public save() {
    if(this.departmentToEdit.id){
      this.departmentService.update(this.departmentToEdit)
        .subscribe(() => this.viewSaveResult('Änderungen gespeichert'))
    } else {
      this.departmentService.add(this.departmentToEdit)
        .subscribe( () => this.viewSaveResult('Neues Referat gespeichert'));
    }
  }

  private viewSaveResult(message: string){
      this.contactDataService.queryContactData().subscribe((result) => {
        this.notifyService.toast('success', message);
        this.departmentToEdit = {} as Department;
        this.inputIsDisabled = true;
      })
  }

  public confirmDelete(department: Department ) {
    const dialogRef = this.dialog.open(DeleteDepartmentDialogComponent, {
      data: {department} as DeleteDepartmentDialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.departmentService.delete(department).subscribe(() => {
          this.notifyService.toast('success', 'Referat wurde gelöscht');
        })
      } else {
        this.notifyService.toast('info', 'Löschen abgebrochen');
      }
    });
  }
}
