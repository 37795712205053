import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Department } from 'src/app/entities/department.entity';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends EntityCollectionServiceBase<Department>{

  private _referat: Department[] = [];

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Department', serviceElementsFactory);
  }

  public get referat(): Department[] {
    return this._referat;
  }

  public set referat(value: Department[]) {
    this._referat = value;
  }
}
